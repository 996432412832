<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
      <a-row>
        <a-col :span="6">
          <div style="max-height: 500px;overflow-y: scroll">
            <a-tree
              checkable
              v-model="checkedKeys"
              @check="onCheck"
              checkStrictly
              :treeData="permissions">
            </a-tree>
          </div>
        </a-col>
        <a-col :span="18">
          <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:4}" :wrapper-col="{span:20}">
            <a-form-model-item label="政策名称" prop="name">
              <a-input v-model="rowData.name" placeholder="政策名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="有效期" prop="validityStart">
              <RangePicker :startTime.sync="rowData.validityStart" :endTime.sync="rowData.validityEnd" :show-time="true"  :disabled-date="disabledDate"></RangePicker>
            </a-form-model-item>
            <a-form-model-item label="政策有效次数" prop="(flagLimit">
              <div style="display: flex; margin-top: 8px;">
                <a-radio-group v-model="rowData.flagLimit" @change="onCheckLimit">
                  <a-radio :value="false">不限次数</a-radio>
                  <a-radio :value="true">限制次数</a-radio>
                </a-radio-group>
                <a-input v-if="flagLimit == true" v-model="rowData.limitCount" style="width: 120px;" placeholder="请输入限制次数"></a-input>
              </div>
            </a-form-model-item>
            <a-form-model-item label="是否加入折扣" prop="joinRebate">
              <a-select v-model="rowData.joinRebate" placeholder="请选择">
                <a-select-option v-for="(item, index) in options" :key="index" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="是否参与积分" prop="joinIntegral">
              <a-select v-model="rowData.joinIntegral" placeholder="请选择">
                <a-select-option v-for="(item, index) in options1" :key="index" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="上传文件路径" prop="(fileUrl">
              <QiniuUpload type="image" :value.sync="rowData.fileUrl"></QiniuUpload>
            </a-form-model-item>
            <a-form-model-item label="政策商品类型" prop="policyGoodsType">
              <a-radio-group v-model="rowData.policyGoodsType" @change="onCheckPro">
                <a-radio :value="1">选择全部商品</a-radio>
                <a-radio :value="2">选择商品分类</a-radio>
                <a-radio :value="3">选择具体商品</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="折扣率" v-if="classifyType === 1 || classifyType === 2">
              <a-input v-if="classifyType === 1" v-model="rowData.allGoodsDiscount" placeholder="请输入折扣率"></a-input>

              <a-table v-else-if="classifyType === 2" :data-source="tableData" bordered>
                <a-table-column title="分类" align="center" :ellipsis="true" data-index="name"></a-table-column>
                <a-table-column title="折扣率" align="center" :ellipsis="true" data-index="discountNumStr">
                  <template slot-scope="text, row">
                    <a-input-number v-model="row.discountNumStr" :min="0"></a-input-number>
                  </template>
                </a-table-column>
              </a-table>
            </a-form-model-item>
            <a-form-model-item label="选择商品" v-if="classifyType === 3">
              <a-button type="primary" @click="onCheckProduct">添加商品</a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
      <a-table v-if="dataList.length > 0" :data-source="dataList" bordered>
        <a-table-column title="商品名称" data-index="name" align="center" :ellipsis="true"></a-table-column>
        <a-table-column title="商品图片" data-index="imgUrl" align="center" :ellipsis="true"></a-table-column>
        <a-table-column title="建议零售价" data-index="goodsPrice" align="center" :ellipsis="true"></a-table-column>
        <a-table-column title="品牌" data-index="brandName" align="center" :ellipsis="true"></a-table-column>
        <a-table-column title="分类" data-index="categoryName" align="center" :ellipsis="true"></a-table-column>
        <a-table-column title="政策价格" data-index="goodsPrice" align="center" :ellipsis="true">
          <template slot-scope="text, row">
            <a-input-number v-model="row.policyPrice" :min="0" @change="onChangePrice(row)"></a-input-number>
          </template>
        </a-table-column>
        <a-table-column title="政策折扣率(%)" data-index="discountNumStr" align="center" :width="135" :ellipsis="true">
          <template slot-scope="text, row">
            <a-input-number v-model="row.discountNumStr" @change="onChangeDiscount(row)" :min="0"></a-input-number> %
          </template>
        </a-table-column>
        <a-table-column title="操作" data-index="" align="center" :ellipsis="true">
          <template slot-scope="text, row">
            <a @click="onDelete(row.goodsId)">删除</a>
          </template>
        </a-table-column>
      </a-table>
      <ProductModal ref="ProductModal" @add-product="onAddProduct"></ProductModal>
    </a-modal>
</template>

<script>
import { editPolicyInfo, selectByIdPolicyInfo, addPolicyInfo } from '../api/PolicyInfoApi'
import moment from "moment"
import ProductModal from './ProductModal.vue'

export default {
    components: {
      ProductModal
    },
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '请输入政策名称', trigger: 'blur' }
                ],
                validityStart: [
                    { required: true, message: '请输入有效期开始', trigger: 'blur' }
                ],
                flagLimit: [
                    { required: true, message: '请输入是否限制次数', trigger: 'blur' }
                ],
                limitCount: [
                    { required: true, message: '请输入限制次数', trigger: 'blur' }
                ],
                joinRebate: [
                    { required: true, message: '请输入是否加入折扣', trigger: 'blur' }
                ],
                joinIntegral: [
                    { required: true, message: '请输入是否参与积分', trigger: 'blur' }
                ],
                fileUrl: [
                    { required: true, message: '请输入上传文件路径', trigger: 'blur' }
                ],
                policyGoodsType: [
                    { required: true, message: '请输入政策商品类型', trigger: 'blur' }
                ],
            },
            permissions: [],
            checkedKeys: [],
            flagLimit: 1,
            options: [
              {
                id: 1,
                name: '参与折扣'
              },
              {
                id: 2,
                name: '不参与折扣'
              },
            ],
            options1: [
            {
              id: 1,
              name: '参与积分'
            },
            {
              id: 2,
              name: '不参与积分'
            },
          ],
            tableData: [],
            classifyType: 0,
            dataList: [],
            categoryList: [],
            expandedKeys: [],
            categorylist: []
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData.validityEnd = null
            this.rowData.validityStart = null
            this.rowData = {}
            this.checkedKeys = []
            this.dataList = []
            this.tableData = []
            if(handle === 'add') {
              this.$set(this.rowData, 'flagLimit', true)
              this.flagLimit = true
            }else if(handle === 'edit') {
              selectByIdPolicyInfo(row.id).then(res => {
                this.rowData = res.body
                this.checkedKeys = res.body.participateIds
                this.expandedKeys = res.body.participateIds
                if(res.body.joinIntegral) {
                  this.rowData.joinIntegral = 1
                }else {
                  this.rowData.joinIntegral = 2
                }
                if(res.body.joinRebate) {
                  this.rowData.joinRebate = 1
                }else {
                  this.rowData.joinRebate = 2
                }
                let list = res.body.policyGoodsDiscountWithProInfoVOList != null ? res.body.policyGoodsDiscountWithProInfoVOList : []
                this.categorylist = res.body.categoryDiscountList
                this.getTreeList(res.body.categoryDiscountList)
                this.dataList = list.map(i => {
                  return {
                    goodsId: i.goodsId,
                    name: i.proName,
                    url: i.imgUrl,
                    goodsPrice: i.goodsPrice,
                    policyPrice: i.policyPrice,
                    brandName: i.bannerName,
                    categoryName: i.cateName,
                    discountNumStr: i.discountNumStr,
                  }
                })
                if(this.dataList.length > 0) {
                  this.classifyType = 3
                }else if(res.body.categoryDiscountList.length > 0) {
                  this.classifyType = 2
                }
              })
            }
            this.getTreeList([])
        },

        getTreeList(data) {
          this.axios.post('/api/dealer/dealer/dealerInfo/getTree').then(res => {
            this.permissions = res.body
          })
          this.axios.get('/api/product/category/parentCategoryList' ).then(res => {
            let list = res.body
            this.tableData = list.map(e => {
              return {
                categoryId: e.id,
                name: e.name,
                discountNumStr: 0
              }
            })
            let item = this.tableData
            if(this.handle === 'edit') {
              item.filter(ele => {
                data.find(item => {
                  return ele.categoryId === item.categoryId ? ele.discountNumStr = item.discountNumStr : 0
                })
              })
            }
          })
        },

      // 监听政策价格推出政策折扣率
      onChangePrice(row) {
          this.dataList.filter(ele => {
            if(ele.goodsId === row.goodsId) {
              return ele.discountNumStr = (row.policyPrice / row.goodsPrice * 100).toFixed(2)
            }
          })
      },
      // 监听政策折扣率反推出政策价格
      onChangeDiscount(row) {
        this.dataList.filter(ele => {
          if(ele.goodsId === row.goodsId) {
            return ele.policyPrice = (row.goodsPrice * (row.discountNumStr / 100)).toFixed(2)
          }
        })
      },

        onCheckProduct() {
          this.$refs.ProductModal.isShow()
        },

        onCheck(keys) {
          this.rowData.participateIds = keys.checked
        },

        disabledDate(current) {
          return current && current <= moment();
        },

        onAddProduct(data) {
          let vo = []
          let arr = new Map()
          data.forEach(e => {
            vo.push({
              goodsId: e.id,
              name: e.name,
              url: e.imgUrl,
              goodsPrice: e.sellPrice,
              policyPrice: e.sellPrice,
              brandName: e.brandName,
              categoryName: e.categoryName,
              discountNumStr: 100
            })
          })
          let list = this.dataList.concat(vo)
          this.dataList = list.filter(e => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))
        },

        // 监听是否限制次数
        onCheckLimit(val) {
          this.flagLimit = val.target.value
        },

        // 删除商品
        onDelete(id) {
          this.dataList.splice(this.dataList.findIndex((e) => { return e.id === id }), 1)
        },

        onCheckPro(val) {
          this.classifyType = val.target.value
          if(this.classifyType === 1) {
            this.tableData = []
            this.dataList = []
          }else if(this.classifyType === 2) {
            this.dataList = []
            this.rowData.allGoodsDiscount = ''
          }else if(this.classifyType === 3) {
            this.tableData = []
            this.rowData.allGoodsDiscount = ''
          }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                let list = this.tableData.filter(e => e.discountNumStr != 0)
                this.rowData.goodsDiscountList = this.dataList
                this.rowData.categoryDiscountList = list
                if(this.rowData.policyGoodsType === 2) {
                  if(this.rowData.categoryDiscountList.length <= 0) {
                    return this.$notification.warning({message: '请添加至少一个分类政策折扣率'})
                  }
                }
                if(this.rowData.policyGoodsType === 3) {
                  if(this.rowData.goodsDiscountList.length <= 0) {
                    return this.$notification.warning({message: '请添加至少一个商品'})
                  }
                }
                const res = this.handle === 'add' ? await addPolicyInfo(this.rowData) : await editPolicyInfo(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
